const colorSet = [
  { color: '#003f5c', light: true },
  { color: '#2f4b7c', light: true },
  { color: '#665191', light: true },
  { color: '#a05195', light: false },
  { color: '#d45087', light: false },
  { color: '#f95d6a', light: false },
  { color: '#ff7c43', light: false },
  { color: '#ffa600', light: false },
  { color: '#00475d', light: true },
  { color: '#005f66', light: true },
  { color: '#00765e', light: true },
  { color: '#038b48', light: true },
  { color: '#669c25', light: true },
  { color: '#b0a600', light: false },
  { color: '#0076ac', light: true },
  { color: '#5678c2', light: true },
  { color: '#9673c8', light: false },
  { color: '#cf6abb', light: false },
  { color: '#fa629e', light: false },
  { color: '#ff6a75', light: false },
  { color: '#ff8447', light: false },
  { color: '#0074a9', light: true },
  { color: '#5576bf', light: true },
  { color: '#9472c6', light: false },
  { color: '#cd69ba', light: false },
  { color: '#f9629d', light: false },
  { color: '#ff6a75', light: false },
  { color: '#ff8447', light: false },
  { color: '#003046', light: true },
];

export default colorSet;
